import React from 'react';

import Fade from 'react-reveal/Fade';
import icon from '../../assets/img/Icon.svg'

import { useTranslation } from "react-i18next"; 

function Vacancy() {

    const { t } = useTranslation();
  return (
    <>
    <div className='w-[1200px] mx-auto mt-[115px] xl:block sm:hidden hidden'>
    <h1 className='text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px]'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='ml-[35px] bg-[#F4F4F4] w-[383px] h-[327px] py-[50px] pr-[39px] pl-[38px]'>
                    <img src={icon} alt="" className='absolute top-[-60px] left-[130px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[20px] text-[16px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[15px] text-[16px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    {/* Tablet Block */}
    <div className='mx-auto mt-[115px] xl:hidden sm:block hidden'>
        <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='flex justify-center flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p></div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')} </p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
            </Fade>
        </div>
        <div className='flex flex-row mt-[60px] w-[700px] mx-auto'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='ml-[40px] bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    {/* Tablet Block */}
    {/* Mobile Block */}
    <div className='flex flex-col mx-auto mt-[76px] sm:hidden'>
        <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] font-bold text-center rubik-font'>{t('33company_vacancies')}</h1>
        <div className='mt-[50px] mx-auto flex flex-col'>
            <Fade bottom>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px]'>
                    <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                    <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('34customer_success_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('35active_customer_contact')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('36customer_retention_strategies')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>    
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('37product_manager')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('38product_lifecycle_management')} </p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('39strategic_planning_and_communication')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('40business_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('41business_process_and_data_analysis')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('42report_creation_and_strategic_planning')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('43researcher')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('44market_competitor_and_trend_research')}</p>
                    <p className='mt-[3px] text-[13px] font-light leading-[24px] rubik-font'>{t('45development_of_reports_and_recommendations')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('46technical_support_specialist')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('47resolution_of_technical_issues_and_customer_support')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('48interaction_with_internal_developers')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('49web_design')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('50development_of_attractive_designs_for_websites_and_apps')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('51implementation_of_graphical_concepts_and_interfaces_with_user_friendly_approach')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('52financial_analyst')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('53analysis_of_financial_data_and_creation_of_reports')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('54forecasting_financial_trends_and_developing_financial_management_strategies')}</p>
                </div>
                <div className='bg-[#F4F4F4] w-[330px] h-[215px] py-[25px] pr-[25px] pl-[25px] mt-[30px]'>
                <img src={icon} alt="" className='absolute top-[-70px] left-[100px]'/>
                <h1 className='text-[20px] leading-[30px] font-medium rubik-font'>{t('55customer_care')}</h1>
                    <p className='mt-[15px] text-[13px] font-light leading-[24px] rubik-font'>{t('56responding_to_questions_solving_problems_and_ensuring_high_customer_satisfaction')}</p>
                    <p className='mt-[8px] text-[13px] font-light leading-[24px] rubik-font'>{t('57collecting_and_analyzing_feedback_for_continuous_service_improvement')}</p>
                </div>
            </Fade>
        </div>
    </div>
    </>
    
  );
}

export default Vacancy;