import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo1.svg'
import logo2 from '../assets/img/ph_slack-logo-duotone.svg'

import { Link } from 'react-scroll';

import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const languages = [
  { value: 'ua', label: 'UA' },
  { value: 'en', label: 'EN' },
  { value: 'ru', label: 'RU' },
  { value: 'pl', label: 'PL' },
];

function Header() {
  const { t } = useTranslation();
  const storedLang = Cookies.get("i18next");
  const [currentLang, setCurrentLang] = useState(storedLang || 'ua');

  useEffect(() => {
    if (storedLang && storedLang !== currentLang) {
      setCurrentLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, [storedLang]);

  const handleChangeLang = (selectedLang) => {
    setCurrentLang(selectedLang.value);
    i18n.changeLanguage(selectedLang.value);
    Cookies.set("i18next", selectedLang.value);
  };

    return (
      <>
        <header className='bg-white flex justify-center sm:flex hidden'>
          <div className='bg-[#212121] w-full inline-flex h-[80px] items-center py-[10px] xl:px-[150px] md:px-[10px]'>
          <NavLink className="md:hidden xl:block hidden sm:block" to="/"><img src={logo} alt=""/></NavLink>
          <div className="flex gap-[60px] items-center ml-auto">
            <Link to="about" spy={true} smooth={true} offset={-70} duration={500} className="cursor-pointer font-sans font-light leading-normal text-[16px] text-white pl-[10px] hidden sm:block">{t('header_1')} </Link>
            <Link to="service" spy={true} smooth={true} offset={-70} duration={500} className="cursor-pointer font-sans font-light leading-normal text-[16px] text-white hidden xl:block">{t('header_2')}</Link>
            <Link to="servicetablet" spy={true} smooth={true} offset={-70} duration={500} className="cursor-pointer font-sans font-light leading-normal text-[16px] text-white block xl:hidden">{t('header_2')}</Link>
            <Link to="vacancy" spy={true} smooth={true} offset={-70} duration={500} className="cursor-pointer font-sans font-light leading-normal text-[16px] text-white hidden sm:block">{t('header_3')}</Link>
            <Link to="rating" spy={true} smooth={true} offset={-70} duration={500} className="cursor-pointer font-sans font-light leading-normal text-[16px] text-white hidden sm:block">{t('header_4')}</Link>
            <div className="font-sans font-light">
              <Dropdown
                options={languages}
                onChange={handleChangeLang}
                value={languages.find(lang => lang.value === currentLang)}
                placeholder="UA"
              />   
            </div>     
          </div>  
          </div>
        </header>

        <header className='bg-[#212121] w-full h-[50px] sm:hidden'>
          <div className="flex">
            <NavLink className="flex pt-[9px] pl-[18px]" to="/"><img src={logo2} className='w-[32px] h-[32px]' alt=""/></NavLink>
            <div className="dropdownown font-sans font-light pt-[5px]">
              <Dropdown
                options={languages}
                onChange={(e) => i18n.changeLanguage(e.value)}
                value={currentLang}
                placeholder="UA"
              />   
            </div> 
          </div>
        </header>
      </>
      
    );
  }
  
  export default Header;
  